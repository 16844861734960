import type { NextResponse } from 'next/server';
import generateUuid from './generateUuid';
import { getCookie, setCookie } from 'cookies-next';
import { getClickId, type clickIds } from './clickId';

export const eventMetadataKey = 'event_metadata';
export const eventMetadataMaxAge = 1800; // 30 minutes in seconds

export type AdditionalMetadata = {
  clickId?: Partial<Record<(typeof clickIds)[number], string>>;
  utm?: {
    [key: string]: string;
  };
};

/**
Assigns user tracking and UTM attribution metadata to the response cookie.
*/
export const setEventMetadata = (
  response: NextResponse,
  eventMetadataCookie: string | undefined,
  options?: AdditionalMetadata
) => {
  let value;
  // Checks if an `event_metadata` cookie does not exist.
  if (!eventMetadataCookie) {
    // Creates a Base64 serialized JSON object to store as the cookie value.
    value = JSON.stringify({
      ...options?.clickId,
      segment_session_id: generateUuid(),
      utm: options?.utm,
    });
  } else {
    // If UTM values were sent via options, the cookie value is updated and the `segment_session_id` persists if present.
    const { recent_job_draft_id, segment_session_id, utm } =
      JSON.parse(eventMetadataCookie);
    const clickId = options?.clickId ?? getClickId('', eventMetadataCookie);

    value = JSON.stringify({
      ...clickId,
      recent_job_draft_id,
      segment_session_id,
      utm: options?.utm ?? utm,
    });
  }

  // The `event_metadata` cookie's `value` and `maxAge` option are updated on response.
  response.cookies.set(eventMetadataKey, value, {
    maxAge: eventMetadataMaxAge,
  });
};

/**
 * Update the event_metadata cookie with the most recent job_draft_id and
 * returns a boolean based on the job_draft_id.
 */
export const updateEventMetadataWithRecentJobDraftId = (
  jobDraftId?: string
) => {
  const eventMetadataCookieValue = getCookie(eventMetadataKey);

  const eventMetadata =
    typeof eventMetadataCookieValue === 'string' &&
    eventMetadataCookieValue.length
      ? JSON.parse(eventMetadataCookieValue)
      : {};
  const data = { ...eventMetadata };

  if (!data.segment_session_id) data.segment_session_id = generateUuid();

  data.recent_job_draft_id = !jobDraftId
    ? null
    : `${data.segment_session_id}-${jobDraftId}`;

  setCookie(eventMetadataKey, JSON.stringify(data), {
    maxAge: eventMetadataMaxAge,
  });

  return eventMetadata.recent_job_draft_id !== data.recent_job_draft_id;
};

export const getSegmentSessionId = () => {
  const value = getCookie(eventMetadataKey);
  const isCookiePresent = value && typeof value === 'string';

  if (!isCookiePresent) return null;

  const { segment_session_id } = JSON.parse(value);
  return String(segment_session_id);
};
